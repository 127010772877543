import React, { useContext, useEffect, useState } from "react";
import PropTypes from "prop-types";
import {
  Map,
  TileLayer,
  Popup,
  Circle,
  Marker,
  GeoJSON,
  Tooltip,
} from "react-leaflet";
import "style/leaflet.css";
import ReactLeafletKml from "react-leaflet-kml";
import axios from "axios";
import { LocaleContext } from "context/locale-context";
import LocalizedLink from "components/localizedLink";
import { isNull } from "utils/helper";

function YemeniMap({
  units,
  hoveredUnitID,
  viewport = { lat: 15.5, lng: 45, zoom: 7 },
  road,
  page,
  mapNo,
}) {
  const [kmlData, setKmlData] = useState({
    N1: null,
    N2: null,
    N3: null,
    N4: null,
    N5: null,
    allRoads: null,
    map3: null,
    map4: null,
    map9: null,
    houthi2017: null,
    houthi2018: null,
    houthi2019: null,
  });

  const locale = useContext(LocaleContext);
  const isRTL = locale === "ar";

  const MarkerRadius = (unit) => (hoveredUnitID === unit.code ? 10000 : 0);
  const MarkerColor = (unit) =>
    hoveredUnitID === unit.code ? "#008080" : "#E95351";
  const isIndex = page === "index";
  const isMap3 = isNull(units) || (units[0].map === 3 && units.length) === 2;
  const isMap4 = isNull(units) || units[units.length - 1].map === 4;
  const isMap12 = mapNo === "map12";
  const isMap13 = (mapNo = "map13");
  const position = [viewport.lat, viewport.lng];
  const tooUnits = isNull(units) || units.length >= 20;

  useEffect(() => {
    if (kmlData[road] == null && road != null) {
      axios.get(`https://bridges.yemeniarchive.org/${road}.kml`).then((res) => {
        const parser = new DOMParser();
        const kml = parser.parseFromString(res.data, "text/xml");
        setKmlData({ ...kmlData, [road]: kml });
      });
    }
  }, [road]);

  if (typeof window !== "undefined") {
    return (
      <>
        <Map
          center={position}
          zoom={viewport.zoom}
          className="leaflet-map"
          scrollWheelZoom={isIndex ? false : true}
          zoomControl={isIndex ? false : true}
          attributionControl={false}
        >
          <TileLayer url="https://tiles.stadiamaps.com/tiles/osm_bright/{z}/{x}/{y}{r}.png" />

          {kmlData[road] && <ReactLeafletKml kml={kmlData[road]} />}

          {isNull(units) ||
            units.map((unit) => {
              return (
                <Marker
                  key={unit.code}
                  position={[unit.latitude, unit.longitude]}
                  riseOnHover={true}
                  //opacity={isMap3 ? 1 : 0}
                  opacity={1}
                  icon={L.icon({
                    iconUrl: isMap3
                      ? "./port.png"
                      : isMap12
                      ? "Bridges.svg"
                      : isMap4
                      ? "./ss.png"
                      : tooUnits
                      ? "./ss.png"
                      : "./YA.png",
                    iconSize: isMap12
                      ? [30, 30]
                      : isMap3
                      ? [26, 29]
                      : isMap4
                      ? [1, 1]
                      : tooUnits
                      ? [1, 1]
                      : [20, 30],
                    iconAnchor: isMap12
                      ? [30, 30]
                      : isMap3
                      ? [30, 40]
                      : [20, 30],
                  })}
                  //opacity={0}
                >
                  <Popup>
                    <span>
                      <LocalizedLink to={`data/incident/${unit.code}`}>
                        {isRTL ? unit.name_ar : unit.name_en}
                      </LocalizedLink>
                    </span>
                  </Popup>
                  {tooUnits || (
                    <Tooltip
                      direction="right"
                      offset={[-8, 0]}
                      opacity={1}
                      permanent
                    >
                      <span>{isRTL ? unit.name_ar : unit.name_en}</span>
                    </Tooltip>
                  )}
                  <Circle
                    center={{ lat: unit.latitude, lng: unit.longitude }}
                    //                    color={MarkerColor(unit)}
                    fill={true}
                    stroke={false}
                    //fillColor=" #b32c50"
                    fillColor={
                      isMap13
                        ? unit.code === "INF_00571"
                          ? "#abb1ad"
                          : "#b32c50"
                        : "#b32c50"
                    }
                    radius={5000}
                    fillOpacity={0.75}
                    pane="markerPane"
                  />
                </Marker>
              );
            })}
        </Map>
      </>
    );
  }
  return null;
}

YemeniMap.propTypes = {
  //  units: PropTypes.func,
  hoveredUnitID: PropTypes.string,
};

export default YemeniMap;
